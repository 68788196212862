import React, { Component } from "react";
import Card from "react-md/lib/Cards/Card";
import CardText from "react-md/lib/Cards/CardText";
import CardTitle from "react-md/lib/Cards/CardTitle";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Media from "react-md/lib/Media/Media";
import MediaOverlay from "react-md/lib/Media/MediaOverlay";
import Divider from "react-md/lib/Dividers/Divider";
import UserLinks from "../UserLinks";
import config from "../../../data/SiteConfig";
import "./Registry.scss";

class Registry extends Component {
  render() {
    return (
      <div className="md-grid mobile-fix">
        <Grid className="wrapper">
          <Cell size={3}></Cell>
          <Cell size={6}>
            <h2 className="md-headline">Your transaction may be subject to the Cape Town Convention and the International Registry if it involves...</h2>
            <ul>
              <li>An Airframe type certified to transport at least 8 people or goods in excess of 2750 kg</li>
              <li>A Helicopter type certified to transport at least 5 people or goods in excess of 450 kg</li>
              <li>An Engine (turbine or piston-powered) with at least 550 rated take-off shaft horsepower</li>
              <li>An Engine (jet propulsion) with at least 1750 pounds of thrust</li>
            </ul>
            <h3>Other Considerations</h3>
            <ul>
              <li>Is the Aircraft registered in a Contracting State?</li>
              <li>Is the Seller or Debtor situated in a Contracting State?</li>
            </ul>
            <p>As of February 22, 2019, eighty (80) countries and regional economic organizations have ratified or acceded to the Convention.</p>
          </Cell>
          <Cell size={3}></Cell>
        </Grid>
        <Card className="md-grid md-cell--8">
          <div className="centered">
            <CardText>
              <p className="md-body-1 centered">
                We can help you determine if you have a Cape Town Transaction.  If you do, we offer all the support you need to navigate this complex area and protect the priority of your financial interest.
              </p>
            </CardText>
          </div>
        </Card>
      </div>
    );
  }
}

export default Registry;
